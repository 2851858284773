import React from 'react';
import { Element } from 'react-scroll';
import { ElementProps } from 'react-scroll/modules/components/Element';
import styled from 'styled-components';
import ContentWrapper from '../ContentWrapper';

export interface Props extends Omit<ElementProps, 'as'> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    as?: keyof JSX.IntrinsicElements | React.ComponentType<React.PropsWithChildren<any>>;
}

const Section = styled(Element).attrs(attrs => ({ as: 'section', ...attrs }))<Props>`
    --sectionPadding: var(--wrapperPadding);
    position: relative;

    > ${ContentWrapper} {
        padding: var(--sectionPadding) var(--wrapperPadding);
    }
`;

export default Section;
