import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import Body, { BodyVariant } from '@oberoninternal/travelbase-ds/components/primitive/Body';
import useSesame from '@oberoninternal/travelbase-ds/hooks/useSesame';
import React, { CSSProperties, FC, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';

interface Props {
    text: string;
    maxLength?: number;
    variant?: BodyVariant;
    alwaysClamp?: boolean;
    style?: CSSProperties;
}

const TextClamp: FC<React.PropsWithChildren<Props>> = ({
    text,
    maxLength = 246,
    variant = 'large',
    alwaysClamp = false,
    style,
}) => {
    const { open, onToggle } = useSesame(text.length < maxLength);
    const ref = useRef<HTMLButtonElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    return (
        <div ref={containerRef} style={style}>
            <Inner variant={variant} open={open} alwaysClamp={alwaysClamp}>
                {text}
            </Inner>
            {text.length > maxLength && (
                <StyledButton
                    ref={ref}
                    variant="outline"
                    onClick={() => {
                        if (open) {
                            onToggle();
                            if (containerRef.current && containerRef.current?.getBoundingClientRect().top < 0) {
                                setTimeout(
                                    () => ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' }),
                                    100
                                );
                            }
                        } else {
                            onToggle();
                        }
                    }}
                    alwaysClamp={alwaysClamp}
                >
                    {!open && <FormattedMessage defaultMessage="Lees meer" />}
                    {open && <FormattedMessage defaultMessage="Lees minder" />}
                </StyledButton>
            )}
        </div>
    );
};

const Inner = styled(Body)<{ open: boolean; alwaysClamp: boolean }>`
    max-height: ${({ open }) => (open ? 'unset' : 'calc(2rem * 7)')};
    position: relative;
    overflow: hidden;

    ${({ alwaysClamp }) =>
        !alwaysClamp &&
        css`
            @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
                max-height: unset;
            }
        `};

    :after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 5.6rem;
        background: linear-gradient(-180deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
        transition: opacity 0.3s ease;

        opacity: ${({ open }) => (open ? '0' : '1')};

        transform: translateZ(0);

        ${({ alwaysClamp }) =>
            !alwaysClamp &&
            css`
                @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
                    display: none;
                }
            `};
    }
`;

const StyledButton = styled(Button)<{ alwaysClamp: boolean }>`
    margin-top: 2.4rem;

    ${({ alwaysClamp }) =>
        !alwaysClamp &&
        css`
            @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
                display: none;
            }
        `};
`;

export default TextClamp;
