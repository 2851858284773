import { StaticMapProps } from '@oberoninternal/travelbase-ds/components/misc/StaticMap';
import { useDeviceSize } from '@oberoninternal/travelbase-ds/context/devicesize';
import dynamic from 'next/dynamic';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useTenantContext } from '../context/TenantContext';
import { ZOOM_DEFAULT, ZOOM_MIN } from '../constants/map';

const StaticMap = dynamic(() => import('@oberoninternal/travelbase-ds/components/misc/StaticMap'), { ssr: false });

type Props = Pick<StaticMapProps, 'markerPosition' | 'position' | 'iconUrl' | 'iconUrl2x' | 'markerElement'>;

// const isDev = process.env.NODE_ENV !== 'production';
// can't ask mapbox to download images hosted on localhost of course, so these are fallbacks.
// also we can't use the origin in order to use the static images in de public folder because of that the test env is password protected, so:
// TODO: replace fallbacks with origin urls

export const devMarkerUrl = 'https://i.imgur.com/qEhX1Ah.png';
export const devMarkerUrl2x = 'https://i.imgur.com/QwAO0Qp.png';

const withDevFallback = (url: string, fallback: string) => (process.env.NODE_ENV === 'development' ? fallback : url);

const LocationMap: FC<Props> = ({ iconUrl, iconUrl2x, ...props }) => {
    const { brandConfig } = useTenantContext();
    const { mapZoom, mapMinZoom } = brandConfig;
    const deviceSize = useDeviceSize();
    const mapMinZoomDeviceSize =
        (deviceSize === 'laptop' || deviceSize === 'desktop' ? mapMinZoom?.desktop : mapMinZoom?.mobile) ?? ZOOM_MIN;
    return (
        <StyledStaticMap
            iconUrl={iconUrl ? withDevFallback(iconUrl, devMarkerUrl) : devMarkerUrl}
            iconUrl2x={iconUrl2x ? withDevFallback(iconUrl2x, devMarkerUrl2x) : devMarkerUrl2x}
            showMapText={<FormattedMessage defaultMessage="Kaart tonen" />}
            zoom={mapZoom ?? ZOOM_DEFAULT}
            minZoom={mapMinZoomDeviceSize}
            mapboxApiAccessToken={process.env.NEXT_PUBLIC_MAPBOX_KEY ?? ''}
            {...props}
        />
    );
};

export default LocationMap;

const StyledStaticMap = styled(StaticMap)`
    && {
        box-shadow: 0 1.6rem 4rem 0 rgba(59, 118, 160, 0.08), 0 4rem 13rem 0 rgba(59, 118, 160, 0.16);
        height: 45vh;
        margin-left: calc(var(--wrapperPadding) * -1);
        width: 100vw;

        @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
            height: 55vh;
        }

        @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
            margin-left: 0;
            width: 100%;
            min-height: 90rem;
        }

        @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
            margin-top: calc(var(--sectionPadding) * -2);
        }
    }
`;
