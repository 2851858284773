import Img from '../Img';
import { useThemeContext } from '@oberoninternal/travelbase-ds/hooks/useThemeContext';
import React, { ImgHTMLAttributes } from 'react';
import styled from 'styled-components';
import createImgProps, { ImageType } from '../../utils/createImgProps';

export const useTileImageSizes = () => {
    const { mediaQueries } = useThemeContext();
    return `(min-width: ${mediaQueries.m}) 447px, 320px`;
};

export interface TileProps extends ImgHTMLAttributes<HTMLImageElement> {
    jpeg: ImageType;
    webp: ImageType;
}

const Tile = ({ jpeg, webp, ...props }: TileProps) => {
    const sizes = useTileImageSizes();
    return <StyledTile {...createImgProps(jpeg, webp, sizes, props)} />;
};

const StyledTile = styled(Img)`
    margin: 0;
    width: 32rem;
    height: 24rem;
    position: relative;

    @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        width: 44.8rem;
        height: 33.6rem;
    }
`;

export default Tile;
