import RoundButton from '@oberoninternal/travelbase-ds/components/action/RoundButton';
import ArrowLeft from '@oberoninternal/travelbase-ds/components/figure/ArrowLeft';
import ArrowRight from '@oberoninternal/travelbase-ds/components/figure/ArrowRight';
import Img from '../Img';
import Modal, { ModalProps } from '@oberoninternal/travelbase-ds/components/layout/Modal';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import { Maybe } from '@oberoninternal/travelbase-ds/entities/Maybe';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useScrollBoost } from 'react-scrollbooster';
import styled from 'styled-components';
import { TransformFragment } from '../../generated/graphql';
import usePrevious from '../../hooks/usePrevious';
import createImgProps from '../../utils/createImgProps';
import { ImageCategories } from '../../utils/groupImagesByCategory';

type Image = {
    jpeg: Maybe<Partial<TransformFragment>>;
    webp: Maybe<Partial<TransformFragment>>;
};

export type ImagesOrCategories = ImageCategories | Image[];

interface Props extends ModalProps {
    startIndex?: number;
    hideCount?: boolean;
    imagesOrCategories: ImagesOrCategories;
}

const isCategories = (images: ImagesOrCategories): images is ImageCategories =>
    images.some((img: ImagesOrCategories[number]) => 'translatedCategoryName' in img);

const PhotoModal: FC<React.PropsWithChildren<Props>> = ({
    imagesOrCategories,
    open,
    startIndex,
    onClose,
    hideCount,
}) => {
    const [scrollbooster] = useScrollBoost({
        direction: 'horizontal',
        pointerMode: 'mouse',
        scrollMode: 'native',
    });
    const [nextEnabled, setNextEnabled] = useState(true);
    const [prevEnabled, setPrevEnabled] = useState(true);

    const viewportNode = useRef<HTMLDivElement | null>();

    const onScroll = useCallback(() => {
        const vpElement = viewportNode.current;

        if (vpElement) {
            setNextEnabled(Math.round(vpElement.scrollLeft + vpElement.clientWidth) !== vpElement.scrollWidth);
            setPrevEnabled(vpElement.scrollLeft > 0);
        }
    }, []);

    const viewport = useCallback(
        (node: HTMLDivElement | null) => {
            viewportNode.current = node;
            scrollbooster(node);
            node?.addEventListener('scroll', onScroll);
            onScroll();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    // scroll to the index when opening the modal
    const previousOpen = usePrevious(open);
    useEffect(() => {
        if (previousOpen && open) {
            document.getElementById(`photo-gallery-${startIndex}`)?.scrollIntoView({ inline: 'nearest' });
        }
    }, [open, previousOpen, startIndex]);

    const onSlide = useCallback(({ direction = 'next' }: { direction: 'prev' | 'next' }) => {
        const w = window.innerWidth;
        const imgNodes = Array.from(viewportNode.current?.getElementsByClassName('photo-gallery-image') ?? []);
        let closestEl: { absMidX: number; width: number; imgNodesIndex: number; img: Element } | undefined;

        imgNodes.forEach((img, i) => {
            const rect = img.getBoundingClientRect();
            const absMidX = Math.abs(rect.x + rect.width / 2 - w / 2);

            if (!closestEl || closestEl.absMidX > absMidX) {
                closestEl = { absMidX, width: rect.width, imgNodesIndex: i, img };
            }
        });

        if (closestEl) {
            imgNodes?.[closestEl.imgNodesIndex + (direction === 'next' ? 1 : -1)]?.scrollIntoView({
                behavior: 'smooth',
                inline: 'center',
            });
        }
    }, []);

    return (
        <ModalWithOverflow open={open} onClose={onClose} variant="full">
            <Viewport ref={viewport} withCategories={isCategories(imagesOrCategories)}>
                {isCategories(imagesOrCategories) && (
                    <Content>
                        {Object.keys(imagesOrCategories).map((_, categoryIndex) => (
                            <ImageCategory key={categoryIndex} id={`photo-gallery-${categoryIndex}`}>
                                <Label>
                                    <Title variant="tiny">
                                        {imagesOrCategories[categoryIndex].translatedCategoryName}
                                    </Title>
                                    {!hideCount && (
                                        <Body variant="small">
                                            <FormattedMessage
                                                defaultMessage={`{count, number} {count, plural, one {foto} other {foto's}}`}
                                                values={{ count: imagesOrCategories[categoryIndex].images.length }}
                                            />
                                        </Body>
                                    )}
                                </Label>
                                <Images images={imagesOrCategories[categoryIndex].images} />
                            </ImageCategory>
                        ))}
                    </Content>
                )}
                {!isCategories(imagesOrCategories) && (
                    <Content>
                        <ImagesContainer>
                            <Images setId images={imagesOrCategories} />
                        </ImagesContainer>
                    </Content>
                )}
            </Viewport>
            <CarouselNav>
                <LeftButton
                    disabled={!prevEnabled}
                    variant="outline"
                    onClick={() => {
                        onSlide({ direction: 'prev' });
                    }}
                >
                    <ArrowLeft />
                </LeftButton>
                <RightButton
                    disabled={!nextEnabled}
                    variant="outline"
                    onClick={() => {
                        onSlide({ direction: 'next' });
                    }}
                >
                    <ArrowRight />
                </RightButton>
            </CarouselNav>
        </ModalWithOverflow>
    );
};

const Images = ({ images, setId }: { images: Image[]; setId?: boolean }) => (
    <StyledImages>
        {images.map((image, imageIndex) => {
            if (!image.jpeg || !image.webp) {
                return null;
            }
            return (
                <Photo
                    key={imageIndex}
                    ratio={image?.jpeg?.ratio}
                    id={setId ? `photo-gallery-${imageIndex}` : undefined}
                >
                    <svg viewBox={`0 0 ${image?.jpeg?.ratio} 1`} />
                    <Img
                        layout="fill"
                        loading="lazy"
                        {...createImgProps(image.jpeg, image.webp, '100vw', { className: 'photo-gallery-image' })}
                    />
                </Photo>
            );
        })}
    </StyledImages>
);

const ModalWithOverflow = styled(Modal)`
    height: 100vh;
    --gutter: 3.2rem;
    @supports (width: clamp(1px, 1px, 1px)) {
        --gutter: clamp(3.2rem, 5vw, 8rem);
    }
`;

const Viewport = styled.div<{ withCategories?: boolean }>`
    overflow-y: scroll;
    margin: -2rem;
    padding: 5.6rem 2rem;
    height: 100vh;
    position: relative;

    @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        overflow-y: hidden;
        overflow-x: scroll;
        position: absolute;
        height: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 8rem 0 ${({ withCategories }) => (withCategories ? '4rem' : '12rem')};
        --viewport: 100vh - 8rem - 4rem;
        margin: 0;
        cursor: move; /* fallback if grab cursor is unsupported */
        cursor: grab;

        &:active {
            cursor: grabbing;
        }
    }
`;

const Content = styled.div`
    > * + * {
        border-top: 1px solid ${({ theme }) => theme.colors.neutral['20']};
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        display: flex;
        align-items: stretch;
        height: 100%;

        > * + * {
            border: none;
            border-left: 1px solid ${({ theme }) => theme.colors.neutral['20']};
        }
    }

    @media (max-width: ${({ theme }) => theme.mediaQueriesValues.s - 1}px) {
        padding-bottom: ${({ theme }) => theme.spacing['70_XLarge']};
    }
`;

const ImagesContainer = styled.div`
    padding: var(--gutter) 0;
    @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        padding: 0 var(--gutter);
        align-items: flex-end;
        justify-items: flex-start;
        height: 100%;
        --category: 4rem;

        > div {
            height: 100%;
            > div {
                height: 100%;
            }
        }
    }
`;

const ImageCategory = styled.div`
    padding: var(--gutter) 0;

    @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        flex: 0;
        display: grid;
        --category: 8.8rem;
        grid-template-rows: calc(100% - var(--category)) 8rem;
        grid-template-areas:
            'images'
            'category';
        align-items: flex-end;
        justify-items: flex-start;
        padding: 0 var(--gutter);
    }
`;

const StyledImages = styled.div`
    margin-top: ${({ theme }) => theme.spacing['40_Standard']};

    @media (max-width: ${({ theme }) => theme.mediaQueriesValues.s}px) {
        > * + * {
            margin-top: var(--gutter);
        }
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        display: flex;
        margin: 0;
        width: max-content;

        > * + * {
            margin-left: var(--gutter);
        }
    }
`;

const Label = styled.div`
    @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        grid-area: category;
        position: sticky;
        left: 4rem;
        margin: 0;
    }
`;

const Photo = styled.div<{ ratio?: number }>`
    position: relative;
    max-width: 100vw;
    flex: 0;

    svg {
        object-fit: contain;

        @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
            height: calc(
                var(--viewport) - var(--category)
            ); /** using vh instead of 100% as fix for the fact that the browsen doesn't understand what 100% means in certain situations */
        }
    }

    figure {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    img {
        will-change: transform, opacity;
        object-fit: cover;
    }
`;

const CarouselNav = styled.nav`
    position: absolute;
    bottom: 5rem;
    right: 3.2rem;
    z-index: ${({ theme }) => theme.zIndices.modal};

    > * + * {
        margin-left: 0.8rem;
    }

    @media (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        display: none;
    }
`;

const LeftButton = styled(RoundButton)`
    background: white;
    opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
`;

const RightButton = styled(LeftButton)`
    background: white;
    opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
`;

export default PhotoModal;
