import Page from './Page';
import React from 'react';
import Section from './designsystem/Section';
import ContentWrapper from './ContentWrapper';
import { Box } from '@rebass/grid';
import Stack from './Stack';
import Skeleton from 'react-loading-skeleton';
import Loading from './Loading';
import styled from 'styled-components';

const LoadingDetail = ({ withHero }: { withHero: boolean }) => (
    <Page style={{ position: 'relative' }}>
        {withHero && <SkeletonHero />}
        <Section>
            <ContentWrapper>
                <Box width={0.6}>
                    <Stack spacing={2} mb={5}>
                        <Skeleton width="10rem" height="50" />
                        <Skeleton width="10rem" height="50" />
                    </Stack>
                    <Loading spacing={2} />
                </Box>
            </ContentWrapper>
        </Section>
        <SkeletonHero className="lt-s" />
    </Page>
);

export default LoadingDetail;

const SkeletonHero = ({ className }: { className?: string }) => (
    <SkeletonWrapper className={className} ratio={1008 / 1920}>
        <Box width={1} height="100%" style={{ position: 'absolute', top: 0, left: 0 }}>
            <Skeleton width="100%" height="100%" />
        </Box>
    </SkeletonWrapper>
);

const SkeletonWrapper = styled.div<{ ratio: number }>`
    position: relative;
    max-height: calc(100vh - 27.2rem);

    &:before {
        display: block;
        content: '';
        width: 100%;
        padding-top: ${({ ratio }) => Math.round(ratio * 100)}%;
    }
`;
