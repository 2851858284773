import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import Img, { BaseImgProps } from './Img';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import React, { ButtonHTMLAttributes, FC } from 'react';
import styled from 'styled-components';
import DoubleLandscape from './svg/DoubleLandscape.svg';

interface Props {
    ratio: number;
}

const Hero: FC<React.PropsWithChildren<Props & BaseImgProps>> = ({ ratio, className, children, sources, ...props }) => (
    <HeroWrapper ratio={ratio} className={className}>
        <Img
            loading="eager"
            layout="fill"
            sources={sources?.map(source => ({ ...source, sizes: '100vw' }))}
            {...props}
        />
        {children}
    </HeroWrapper>
);

export default Hero;

export const HeroButtonsMobile = styled.div`
    display: flex;
    position: absolute;
    bottom: 1.2rem;
    right: 1.2rem;
    z-index: 1;

    > button {
        :not(:first-of-type) {
            margin-left: 0.4rem;
        }
    }
    @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
        display: none;
    }
`;

export const HeroButtonsDesktop = styled.div`
    display: none;
    @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
        display: flex;
        position: absolute;
        left: 50%;
        bottom: 3.2rem;
        transform: translateX(-50%);
        z-index: 1;
    }
`;

export const HeroPhotosButton = ({ children, ...props }: ButtonHTMLAttributes<HTMLButtonElement>) => (
    <ViewPhotosButton size="small" {...props}>
        <DoubleLandscape />
        <Body variant="small">{children}</Body>
    </ViewPhotosButton>
);

const HeroWrapper = styled.div<{ ratio: number }>`
    position: relative;
    height: calc(-26rem + 100vh);
    max-height: 40vh;

    @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        max-height: 65vh;
    }

    &:before {
        display: block;
        content: '';
        width: 100%;
        padding-top: ${({ ratio }) => Math.round(ratio * 100)}%;
    }
`;

const ViewPhotosButton = styled(Button)`
    background: rgba(0, 0, 0, 0.5);
    border-radius: 0.8rem;
    border: none;
    box-shadow: none;
    z-index: 1;
    @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
        display: none;
    }
`;

export const HeroContainer = styled.div`
    position: relative;
    min-height: 80px;
`;
